<template>
  <div id="gallery">
    <header-app class="space-mobile"></header-app>
    <div class="container pb-lg-4 photo-block">
      <h1 class="text-center pt-4">{{ album }}</h1>
      <div class="row no-gutters">
        <div v-for="(image, index) in images" v-bind:key="index" class="col-lg-3 imgYear">
          <img loading="lazy" v-on:click="$refs.galleryModal.show(image)" class="img-fluid cropped-img gallery-picture"
               :src="image"
               alt=""></div>
      </div>
      <gallery-modal ref="galleryModal"/>
    </div>
    <footer-app></footer-app>
  </div>
</template>

<script>
import Header from "./Header";
import GalleryModal from "./GalleryModal";
import Footer from "./Footer";

export default {
  name: "Gallery",
  components: {
    'header-app': Header,
    'footer-app': Footer,
    'gallery-modal': GalleryModal
  },
  data() {
    return {
      album: "Projekty",
      images: [
        "/foto/Mikulov.jpg",
        "/foto/Ostrov.jpg",
        "/foto/Pasohlavky1.jpg",
        "/foto/Pasohlavky2.jpg",
        "/foto/Pasohlavky3.jpg",
        "/foto/Pasohlavky4.jpg",
        "/foto/Pasohlavky5.jpg",
        "/foto/Pasohlavky6.jpeg",
        "/foto/Pasohlavky7.jpeg",
        "/foto/PlotBrno.jpg",
        "/foto/plotbrno1.jpg",
        "/foto/ValasskeMezirici.jpg",
        "/foto/VodŚany.jpeg",
        "/foto/VyÁkov.jpg"
      ],
    }
  }
}
</script>

<style scoped>
.imgYear {
  color: #eae7d6;
}

.photo-block {
  padding-top: 6em;
}

.imgYear:hover {
  color: white;
}

.cropped-img {
  height: 300px;
  object-fit: cover;
}

.gallery-picture:hover {
  box-shadow: 0 3px 8px 0 #1d212470;
}
@media screen and (max-width: 600px) {
  .space-mobile {
    padding-bottom: 4rem;
  }
}

</style>
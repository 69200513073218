<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 text-right">
            <a class="navbar-brand" href="#">
              <img width="60%" class="img-fluid" src="@/assets/logo.png" alt="Logo">
            </a>
            <button class="navbar-toggler text-center" type="button" data-toggle="collapse" data-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
          <div class="col-lg-6 d-flex ">
            <div class="collapse navbar-collapse text-navigation" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link" href="/">O nás</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#/history">Historie</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#/gallery">Fotogalerie</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#/contact">Kontakt</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
nav {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

li {
  font-size: 18px;
}

.text-navigation {
  flex-basis: auto;
  justify-content: space-around;
}

@media screen and (max-width: 600px) {
  .navbar-brand {
    display: initial;
  }
}
</style>

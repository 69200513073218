<template>
  <div>
    <footer class="text-center text-lg-start bg-light text-muted pt-1">

      <section class="">
        <div class="container text-center text-md-start mt-5">
          <div class="row mt-3">
            <div class="col-md-4 col-lg-4 col-xl-3 mx-auto mb-4">
              <p class="text-center text-lg-left">
                <img width="90%" class="img-fluid" src="@/assets/logo.png" alt="Logo">
              </p>
            </div>

            <div class="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4">
              <h6 class="text-uppercase text-center text-lg-left fw-bold mb-4">
                Adresa
              </h6>
              <p class="text-center text-lg-left">
                <a href="#!" class="text-reset ">MIDUR s.r.o.</a>
              </p>
              <p class="text-center text-lg-left">
                <a href="#!" class="text-reset">Masná 496/7d</a>
              </p>
              <p class="text-center text-lg-left">
                <a href="#!" class="text-reset text-center text-lg-left">Trnitá, 602 00 Brno</a>
              </p>
              <p class="text-center text-lg-left">
                <a href="#!" class="text-reset text-center text-lg-left">
                  C 40640 vedená u Krajského soudu v Brně</a>
              </p>
            </div>

            <!-- Grid column -->
            <div class="col-md-4 col-lg-4 col-xl-3 mx-auto mb-md-0 mb-4">
              <!-- Links -->
              <h6 class="text-uppercase text-center text-lg-left fw-bold mb-4">
                Kontakt
              </h6>
              <p class="text-center text-lg-left">
                <a href="mailto:midur.trubky@email.cz">midur.trubky@email.cz</a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
        © 2021 Copyright:
        <a class="text-reset fw-bold" href="https://dokamar.cz/#/">Dokamar s.r.o.</a>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
a {
  color: #6c757d;
}
a:hover {
  color: #6c757d;
  text-decoration: underline;
}

</style>